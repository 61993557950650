import React from 'react';
// import rectangleImage from '../../../assets/images/Rectangle-8.svg';
import './rectangle8.css';

const Rectangle8 = () => {
  return (
    <img
      src="images/Rectangle-8.svg" 
      alt="Rectangle Traced"
      className="rectangle-image"
    />
  );
};

export default Rectangle8;
