import React, { useContext } from 'react';
import LeftComponent from './leftComponent';
import PlanDetails from './planDetails';
import PlanContext from '../../contexts/PlanContext';
import './PolicyCover.scss';

const PolicyCover = (selectedPolicy) => {
    const { isPlanSelected } = useContext(PlanContext);
    const stats = {
        activeMembers: 200525,
        membersBenefited: 18173,
        amountDisbursed: 28342,
        lastMonthContribution: 34
    }
    return (
        <div className="cancer-cover-container">
            <LeftComponent selectedPolicy={selectedPolicy} />
            <div className="right-component">
                {isPlanSelected ? (
                    <h2 className="header-text">Stats for this plan</h2>
                ) : (
                    <h2 className="header-text">About this cover</h2>
                )}
                <div className="inner-content">
                    {isPlanSelected ? (
                        <div className="plan-stats">
                            <div className="stats-content">
                                <div className="stat-item">
                                    <span>Active members</span>
                                    <span>{stats.activeMembers.toLocaleString()}</span>
                                </div>
                                <div className="stat-item">
                                    <span>Members benefited</span>
                                    <span>{stats.membersBenefited.toLocaleString()}</span>
                                </div>
                                <div className="stat-item">
                                    <span>Amount disbursed</span>
                                    <span>Rs. {stats.amountDisbursed.toLocaleString()}</span>
                                </div>
                                <div className="stat-item">
                                    <span>Last month average contribution</span>
                                    <span>Rs. {stats.lastMonthContribution}</span>
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="terms">
                                <span>Read <a href="https://ittehaad.com/faqs/#terms-and-conditions" target='blank'>Terms and Conditions</a></span>
                            </div>
                        </div>
                    ) : (
                        <PlanDetails />
                    )}
                </div>
            </div>
        </div>
    )
};

export default PolicyCover;
