import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './mobileContributionComponent.scss';
import HealthDeclarationModal from '../Modals/healthDeclarationModal';
import PlanSelector from './planSelector';

const MobileContributionComponent = () => {
    const location = useLocation();
    const selectedPolicy = location.state.selectedPolicy;
    const [selectedUserPlan, setSelectedUserPlan] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = (data) => {
        setOpenModal(true);
        setSelectedUserPlan(data);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleContinue = () => {
        setOpenModal(false);
        navigate('/payment', { state: { selectedPolicy, selectedUserPlan } });
    };
    const userSelectedPolicy = selectedPolicy?.selectedPolicy?.selectedPolicy;
    return (
        <div className="mobile-contribution-component">
            <div className="contribution-header">
                <button className="back-button" onClick={() => navigate(-1)}>&#8592;</button>
                <div className="cancer-cover">
                    <div className="cover-header">
                        <img src='/images/dashboard/ribbon.png' alt='cover' className="cancer-cover-image" style={{height:'45px',width:'40px'}}/>
                        <div className="cover-text">
                            <div className="text">
                                <h3>{userSelectedPolicy?.title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-text"><strong>Please select your monthly contribution limit.</strong></div>
            </div>
            <div className='plan-selector'>
                <PlanSelector handleOpenModal={handleOpenModal} selectedPolicy={selectedPolicy} />
            </div>
            <HealthDeclarationModal
                open={openModal}
                handleClose={handleCloseModal}
                handleContinue={handleContinue}
            />
        </div>
    );
};

export default MobileContributionComponent;
