import React, { useState, useEffect } from 'react';
import './Popup.scss';
import TitleAndLogo from '../../../../components/TitleAndLogo/titleAndLogo';
import { useToast } from '../../../../components/ToastNotification/ToastNotification';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch'; // Import Switch
import { createPlanForPolicy, updateUserPlan } from '../../../../features/api.slice';
import { useAppDispatch } from '../../../../store';

const AddNewPlan = ({ onContinue, wrapperClass, activeTab, planForEdit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const showToast = useToast();
    // planForEdit
    const [planName, setPlanName] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const [isRecommended, setIsRecommended] = useState(false);
    function createPlan() {
        setIsLoading(true);
        const payload = {
            title: planName,
            price: planPrice,
            recommended: isRecommended,
            planID: activeTab,
            onContinue, 
            showToast, 
            setIsLoading,
        };
        dispatch(createPlanForPolicy(payload));
    }
    function updatePlan() {
        setIsLoading(true);
        const payload = {
            title: planName,
            price: planPrice,
            planID: planForEdit.id,
            onContinue, 
            showToast, 
            setIsLoading,
        };
        dispatch(updateUserPlan(payload));
    }
    function onClose() {
        onContinue();
    }
    useEffect(() => {
        if (planForEdit !== null && planForEdit !== undefined) {
            setPlanName(planForEdit.title);
            setPlanPrice(planForEdit.price);
            // If tabForEdit has a recommended property, set it
            if (planForEdit.recommended !== undefined) {
                setIsRecommended(planForEdit.recommended);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={wrapperClass} className={wrapperClass ? wrapperClass : "otp-modal-wrapper"}>
            <div className="otp-modal">
                <div className="title-logo-container">
                    <TitleAndLogo logoColor="pink" />
                    <IconButton
                        className="close-button"
                        onClick={onClose} // Call the onClose function
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <TextField
                    autoFocus
                    margin="dense"
                    label='Plan Name'
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label='Price'
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={planPrice}
                    onChange={(e) => setPlanPrice(e.target.value)}
                />
                <div className="switch-container">
                    <span>Recommended</span>
                    <Switch
                        checked={isRecommended}
                        onChange={() => setIsRecommended(prev => !prev)}
                    />
                </div>

                <button className={isLoading ? 'disabled-button continue-button' : 'continue-button'} disabled={isLoading} onClick={planForEdit ? updatePlan : createPlan}>{planForEdit && isLoading ? 'Updating....' : planForEdit && !isLoading ? 'Update' : !planForEdit && isLoading ? 'Creating' : 'Create'}</button>
            </div>
        </div>
    );
};

export default AddNewPlan;
