import React, { createContext, useState } from 'react';

const ShowSuccessModalContext = createContext();

export const ShowSuccessModalProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <ShowSuccessModalContext.Provider value={{ showModal, setShowModal }}>
            {children}
        </ShowSuccessModalContext.Provider>
    );
};

export default ShowSuccessModalContext;
