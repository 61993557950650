import React, { createContext, useState } from 'react';

const ProfileDropdownContext = createContext();

export const ProfileDropdownProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ProfileDropdownContext.Provider value={{ isOpen, setIsOpen }}>
            {children}
        </ProfileDropdownContext.Provider>
    );
};

export default ProfileDropdownContext;
