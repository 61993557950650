import React, { useState, useEffect } from 'react';
import './titleAndLogo.scss';
// import logoImgPink from '../../assets/images/Rectangle-pink.svg';
// import logoImgWhite from '../../assets/images/Rectangle-white.svg';
// import headerImgPink from '../../assets/images/header-pink.svg';
// import headerImgWhite from '../../assets/images/header-white.svg';

const TitleAndLogo = ({ logoColor = 'white' }) => {
  const [logoSrc, setLogoSrc] = useState('images/Rectangle-pink.svg');
  const [headerSrc, setHeaderSrc] = useState('images/header-pink.svg');

  useEffect(() => {
    // Determine logo and header based on the color prop
    if (logoColor === 'pink') {
      setLogoSrc('images/Rectangle-pink.svg');
      setHeaderSrc('images/header-pink.svg');
    } else {
      setLogoSrc('images/Rectangle-white.svg');
      setHeaderSrc('images/header-white.svg');
    }
  }, [logoColor]);

  return (
    <div className="header-with-logo">
      <img
        src={logoSrc}
        alt="Logo"
        className="logo-image"
      />
      <img
        src={headerSrc}
        alt="Header"
        className="header-image"
      />
    </div>
  );
};

export default TitleAndLogo;
