import React, { useState, useEffect } from 'react';
import './Signup.css';
import Rectangle8 from '../../components/Signup/Rectangle8/rectangle8';
import Form from '../../components/Signup/Form/form';
import TitleAndLogo from '../../components/TitleAndLogo/titleAndLogo';

const SignUp = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 576);
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="image-container">  
      <div className="content">
        <div className="logo-and-text">
          {isMobile ? <TitleAndLogo /> : <Rectangle8 />}
          <div className="text-box">
            We are building affordable & shariah compliant safety nets for everyone
          </div>
          <div className="sub-text-box">
            Financial Assistance, When you need it the most.
          </div>
        </div>
        <Form />
      </div>
    </div>
  );
};

export default SignUp;

