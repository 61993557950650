import React from 'react';
import './planDetails.scss';
import { planDetailsFields } from '../../utils/constants'

const PlanDetails = () => {
    return (
        <ul className="cover-details">
            {planDetailsFields?.map((field, index) => (
                <li key={index}>
                    <img src={field.icon} alt={field.alt} className="icon" />
                    <div>
                        <strong>{field.title}</strong>
                        <p>{field.description}</p>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default PlanDetails;
