import React, { useState, useCallback } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './ToastNotification.scss'; // Import your CSS file for styling

const ToastContext = React.createContext(null); // Context to provide the toast functionality

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]); // State to store the list of toasts

  // Function to add a new toast
  const addToast = useCallback((message, duration = 3000, showCloseIcon = false) => {
    const id = Date.now(); // Unique ID for the toast
    setToasts((prevToasts) => [...prevToasts, { id, message, showCloseIcon, duration }]);
    // Remove the toast after the specified duration
    const timer = setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, duration);

    return () => clearTimeout(timer); // Clear the timeout if needed
  }, []);

  // Function to remove a toast manually
  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="toast-container">
        {toasts?.map((toast) => (
          <div
            key={toast.id}
            className="toast"
            style={{ '--delay-duration': `${toast.duration - 300}ms` }} // Set delay for fadeOut
          >
            {toast.message}
            {toast.showCloseIcon && (
              <IconButton
                size="small"
                className="close-icon"
                onClick={() => removeToast(toast.id)}
                aria-label="close"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

/* Example usage:
const ExampleComponent = () => {
  const showToast = useToast();

  const handleShowToast = () => {
    showToast('This is a dynamic toast notification!', 5000, true); // 5 seconds duration, close icon visible
  };

  return (
    <button onClick={handleShowToast}>Show Toast</button>
  );
};*/
