import React, { useState,useEffect } from 'react';
import './../Dashboard/dashboard.scss';
import { useAppDispatch,useAppSelector } from '../../store';
import {GetPlans} from '../../features/api.slice'
import {allPlansData} from '../../features/api.selector'
import Plans from './Plans/Plans';

function UserPlans ()  {
    const [activeTab, setActiveTab] = useState(1);
    const dispatch = useAppDispatch();
    useEffect(()=>{
        dispatch(GetPlans())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const plans = useAppSelector(allPlansData)
    function setTabs(id) {
        setActiveTab(id);
    }
    const selectedPolicy = plans?.body?.response?.find(item => item.id === activeTab);
    const tabs = plans?.body?.response?.filter(item => item.status === 'active');
    return (
        <div className="dashboard-component">
            <div className="dashboard-content">
                <h2>Your Plan</h2>
                <div className="tab-menu">
                    {tabs?.map((tab) => (
                        <button
                            key={tab.id}
                            className={activeTab === tab?.id ? 'active' : ''}
                            onClick={() => setTabs(tab?.id)}
                        >
                            {tab?.title}
                        </button>
                    ))}
                </div>
                {activeTab  && <Plans selectedPolicy={selectedPolicy}/>}
            </div>
        </div>
    );
};

export default UserPlans;
