import React, { useState } from 'react';
import {useLocation} from 'react-router-dom';
import OTPModal from '../../components/Modal/OTP/otp';
import PincodeModal from '../../components/Modal/PinCode/pinCode';

const ModalContainer = () => {
  const [step, setStep] = useState('pincode'); // Initially, show the OTP modal

  const handleContinue = () => {
    setStep('pincode'); // Move to the Pincode modal when "Continue" is pressed
  };
  const location = useLocation() ; 

  return (
    <div className="modal-container">
      {step === 'otp' && <OTPModal onContinue={handleContinue} />}
      {step === 'pincode' && <PincodeModal data={location?.state}/>}
    </div>
  );
};

export default ModalContainer;
