import React, { useContext, useState } from 'react';
import { TextField, Button, Typography, Box, Paper, Breadcrumbs, Link, Backdrop, useMediaQuery } from '@mui/material';
import './changeNumber.scss';
import OTPModal from '../../components/Modal/OTP/otp';
import ShowSuccessModalContext from '../../contexts/showSuccessModal';
import SuccessModal from '../../components/Modal/SuccessModal/successModal';
import { useToast } from '../../components/ToastNotification/ToastNotification';
import { useAppDispatch } from '../../store';
import { updateContact }from '../../features/api.slice';

const ChangeNumber = ({ onBackToProfile }) => {
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [invalidPhoneNumber, setinvalidPhoneNumber] = useState(false);
    const [phoneHelperText, setPhoneHelperText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const showToast = useToast();
    const dispatch = useAppDispatch();
    const { setShowModal, showModal } = useContext(ShowSuccessModalContext);
    const validateMobileNumber = (number) => {
        // Pakistani mobile number pattern: +92 followed by 10 digits starting with 3
        const pattern = /^(\92|0092)?3\d{9}$|^03\d{9}$/
          ;
        return pattern.test(number);
      };
    const isMobile = useMediaQuery('(max-width:600px)');
    const handleNextClick = () => {
        if(!validateMobileNumber(phoneNumber)) {
            showToast('Please enter correct phone number', 3000);
            return;
        }
        setIsLoading(true);
        const payload ={
            phone: phoneNumber,
            showToast,
            setIsLoading,
            onBackToProfile,
            setShowOTPModal
        }
        dispatch(updateContact(payload))
      
    };
    const handleCloseOTPModal = () => {
        setShowModal(true);
    };

    const handlePhoneNumberChange = (e) => {
        const newValue = e.target.value
        setPhoneNumber(newValue)
        if (/^[0-9-]*$/.test(newValue)) {
            setinvalidPhoneNumber(false);
            setPhoneHelperText('');
        } else {
            setinvalidPhoneNumber(true);
            setPhoneHelperText('Invalid Phone. Only numbers are allowed.');
        }
    };

    return (
        <Paper elevation={0} className="change-number-form">
            <Typography variant="h5" mb={2} className="change-number-title">
                Change number
            </Typography>

            {!isMobile && (<Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<span className="breadcrumb-separator">{'>'}</span>}>
                <Link underline="hover" color="inherit" href="#" onClick={onBackToProfile}>
                    Your Profile
                </Link>
                <Typography color="text.primary">Change number</Typography>
            </Breadcrumbs>)}

            {/* Fullscreen gray background below the breadcrumbs */}
            <Box className="gray-background-container">
                <Box className="gray-background-content" p={2}>
                    <Box mb={2}>
                        <Typography className="field-label">New Mobile Number</Typography>
                        <TextField error={invalidPhoneNumber} helperText={phoneHelperText} onChange={handlePhoneNumberChange} placeholder="Enter new number" fullWidth />
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            className={isLoading ? 'disabled-button next-btn' : "next-btn"}
                            sx={{ textTransform: 'none' }}
                            onClick={handleNextClick}
                            disabled={isLoading}
                        >
                           {isLoading ? 'Updating Contact' : 'Update Contact'}
                        </Button>
                    </Box>
                </Box>
            </Box>

            {showOTPModal && (
                <Backdrop
                    open={showOTPModal}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',  // Full width
                        height: '100vh',  // Full height
                        position: 'fixed', // Ensure it covers the whole screen
                        top: 0,
                        left: 0,
                    }}
                >
                    <OTPModal
                        wrapperClass={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            ...(isMobile ? {
                                width: '80%',
                                height: 'auto',
                                padding: '16px',
                            } : {
                                height: '100%',
                                width: '100%',

                            }),
                        }}
                        onContinue={handleCloseOTPModal}
                    />
                </Backdrop>
            )}

            {showModal && (
                <Backdrop
                    open={showOTPModal}
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200,
                        width: '100vw',  // Full width
                        height: '100vh',  // Full height
                        position: 'fixed', // Ensure it covers the whole screen
                        top: 0,
                        left: 0,
                    }}
                >
                    <SuccessModal
                        message={'Mobile number changed'}
                        buttonText={'Go to profile'}
                        onClickHandler={onBackToProfile}
                    />
                </Backdrop>
            )}
        </Paper>
    );
};

export default ChangeNumber;
