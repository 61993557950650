import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import './successfulUnsubscriptionModal.scss';

const SuccessfulUnsubscriptionModal = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="unsubscribed-modal">
            <Box className="modal-overlay">
                <Box className="unsubscribed-modal-box">
                    <Typography variant="h5" className="modal-title">
                        Unsubscribed Successfully
                    </Typography>
                    <Typography variant="body1" className="modal-subtitle">
                        We’re sad to see you go. However, you can always re-subscribe.
                    </Typography>

                    <Button variant="outlined" onClick={onClose} className="close-button">
                        Close
                    </Button>

                    {/* <Box className="donation-section">
                        <Typography variant="body2" className="donation-text">
                            You can also directly make a donation to ITEHAAD fund for cancer patients
                        </Typography>
                        <Button variant="contained" color="primary" className="donate-button">
                            Make Donation
                        </Button>
                    </Box> */}

                    <Typography variant="body2" className="terms-text">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        Read <a href="https://ittehaad.com/faqs/#terms-and-conditions" target='blank'>Terms and Conditions</a>
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
};

export default SuccessfulUnsubscriptionModal;
