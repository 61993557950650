import React from 'react';
import {
    TextField, Button, Typography, Box, Paper, Alert, Breadcrumbs, Link,
    useMediaQuery
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './ProfilePage.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const EnterDetails = () => {
    const isMobile = useMediaQuery('(max-width:600px)'); 
    const location = useLocation()
    const navigate = useNavigate();
    const selectedPlan = location.state;
    const handleDoneClick = () => {
        navigate('/review-details', { state: selectedPlan });
    };
    const handleBreadcrumbClick = (route) => {
        navigate(route);
    };
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className="profile-container">
                <Typography variant="h5" mb={2} className="profile-title">
                    Enter Details
                </Typography>

                {isMobile && (
                            <Alert
                                severity="warning"
                                sx={{
                                    backgroundColor: '#FBF8E2',
                                    height: 'fit-content',
                                    border: '2px solid #FFF5AC',
                                    width: '90%',
                                    marginBottom: '1rem',
                                    marginTop: '1rem',
                                    fontFamily: 'Inter',
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    lineHeight: '23.1px',
                                    textAlign: 'left',
                                }}
                                
                            >
                                To be eligible for cancer cover please enter your CNIC details. Accurate information is crucial for appropriate treatment cover.
                            </Alert>
                        )}
                {!isMobile && (<Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={<span className="breadcrumb-separator">{'>'}</span>}>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => handleBreadcrumbClick('/dashboard')}
                        style={{ cursor: 'pointer' }}
                    >
                        Select Plan
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => handleBreadcrumbClick('/dashboard')}
                        style={{ cursor: 'pointer' }}
                    >
                        Cancer Cover
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        onClick={() => handleBreadcrumbClick('/payment')}
                        style={{ cursor: 'pointer' }}
                    >
                        Make Payment
                    </Link>
                    <Typography color="text.primary">Enter Details</Typography>
                </Breadcrumbs>)}

                <Box className="profile-content">
                    <Paper elevation={0} className="profile-paper">
                        <Box mb={2}>
                            <Typography className="field-label">Full name</Typography>
                            <TextField placeholder="Enter your full name" fullWidth />
                        </Box>

                        <Box mb={2}>
                            <Typography className="field-label">CNIC</Typography>
                            <TextField placeholder="Enter your CNIC" fullWidth />
                        </Box>

                        <Box mb={2}>
                            <Typography className="field-label">Date of Birth</Typography>
                            <DatePicker
                                format="DD-MM-YYYY"
                                slotProps={{
                                    inputAdornment: {
                                        position: 'start',
                                    },
                                    textField: { placeholder: '09-10-2024' },
                                }}
                                sx={{ width: '100%' }}
                            />
                        </Box>

                        <Box mb={2}>
                            <Typography className="field-label">Email</Typography>
                            <TextField
                                placeholder="Enter your email"
                                fullWidth
                                helperText="Receive important notifications related to your plan and get to know about new plans"
                            />
                        </Box>

                        <Box className="done-btn-container">
                            <Button
                                variant="contained"
                                onClick={handleDoneClick}
                                sx={{
                                    backgroundColor: "#E44E80",
                                    color: "#fff",
                                    padding: "12px 30px",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    borderRadius: "10px",
                                    width: "100%",
                                    boxShadow: "none",
                                }}
                            >
                                Done
                            </Button>
                        </Box>
                    </Paper>

                    {!isMobile && (<Alert
                        severity="warning"
                        sx={{
                            backgroundColor: '#FBF8E2',
                            height: 'fit-content',
                            border: '2px solid #FFF5AC',
                            width: '300px',
                        }}
                    >
                        To be eligible for cancer cover, please enter your CNIC details. Accurate information is crucial for appropriate treatment cover.
                    </Alert>)}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default EnterDetails;
