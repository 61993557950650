import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import dataReducer from "./features/api.slice";
import RootSaga from "./sagas/api.saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: { data: dataReducer },
  middleware: (getDM) =>
    getDM({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(RootSaga);

export default store;

// Export necessary stuff including hooks

export function useAppDispatch() {
  return useDispatch();
}

export const useAppSelector = useSelector;
