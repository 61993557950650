import React from 'react';
import './successModal.scss'; 

const SuccessModal = ({  message, buttonText, onClickHandler }) => {
  return (
    <div className="success-modal-wrapper">
      <div className="success-modal">
        <div className="icon-container">
          <img
             src="images/check.svg" 
            alt="Success"
            className="check-icon"
          />
        </div>
        <p>{message}</p>
        <button className="action-button" onClick={onClickHandler}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
